import Button from "./layouts/Button";
import Content from "./Content";
import { getResponse } from "../services/openaiService";
import { forwardRef, useEffect, useState } from "react";
import Select from 'react-tailwindcss-select';

import PropTypes from 'prop-types';

const options = [
  { value: "Target audience", label: "Target audience", disabled: true },
  { value: "5-10", label: "5-10" },
  { value: "10-15", label: "10-15" },
  { value: "15-24", label: "15-24" },
  { value: "25-29", label: "25-29" },
  { value: "30-34", label: "30-34" },
  { value: "35-39", label: "35-39" },
  { value: "40-44", label: "40-44" },
  { value: "45-49", label: "45-49" },
  { value: "50-54", label: "50-54" },
  { value: "60-64", label: "60-64" },
  { value: "65-69", label: "65-69" },
  { value: "70-74", label: "70-74" },
  { value: "75-80", label: "75-80" },
];

const GeneralForm = forwardRef(({ pageTitle, isProduct, label1, label2, type, btnText, maxLength, minLength }, generalFormRef) => {

  const [formData, setFormData] = useState({
    title: "", message: "", accessibility: "15 years",
    tone: "", reading: { value: "Target audience", label: "Target audience", disabled: true }, words: 30,
    keywords: "", loading: false,
    generatedText: null
  });
  const [finance, setFinance] = useState(false)

  const { title, tone, accessibility, reading, keywords, words, message, generatedText, loading } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const handleReadingChange = (value) => {
    setFormData({ ...formData, reading: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormData({ ...formData, loading: true });
    let prompt;
    if (type === "BLOG_IDEA") {
      prompt = `Generate a list of 5 ${tone} blog ideas about ${message}, using the Keywords [${keywords}], in ${words} words or less.\nIt should not be plagiarised\n It should be original.
      ${finance ? 'Please respect RG146 advice giving rules way and dont give a direct financial advice.' : ''}.
      Make sure that the language is understood by ${accessibility} reading level.`
    } else if (type === "META_DESCRIPTION") {
      prompt = `Write a ${tone} meta description, it is about ${message}, using the Keywords [${keywords}], in ${words} words or less.\nIt should not be plagiarised\n It should be original.
      Make it suitable for people that are between ${reading} years old.
        ${finance ? 'Please respect RG146 advice giving rules way and dont give a direct financial advice.' : ''}.
        Make sure that the language is understood by ${accessibility} reading level.`;
    } else if (type === "PRODUCT_DESCRIPTION") {
      prompt = `Write a ${tone} product description for the following product.\nProduct:${message}, the product name is ${title}, using the Keywords [${keywords}], in ${words} words or less.\nIt should not be plagiarised\n It should be original.
      Make it suitable for people that are between ${reading} years old.
        ${finance ? 'Please respect RG146 advice giving rules way and dont give a direct financial advice.' : ''}.
        Make sure that the language is understood by ${accessibility} reading level.`;
    } else if (type === "REWRITE_PARAGRAPH") {
      prompt = `Please rewrite the following paragraph in a way that maintains the same meaning but uses different words and phrasing in a ${tone} way: ${message}, using the Keywords [${keywords}], in ${words} words or less.
      Make it suitable for people that are between ${reading} years old.
      ${finance ? 'Please respect RG146 advice giving rules way and dont give a direct financial advice.' : ''}.
      Make sure that the language is understood by ${accessibility} reading level.`;
    } else if (type === "EMAIL") {
      prompt = `"Write a ${tone} email about ${message}, and asking the recipient to reach out 
      if they have any questions, and suggest a Subject for the email. 
      End the email with a polite closing such as 'Have a great day'. 
      Using the Keywords [${keywords}], in ${words} words or less.\nMake it suitable for people that are between ${reading} years old."
      ${finance ? 'Please respect RG146 advice giving rules way and dont give a direct financial advice.' : ''}.
      Make sure that the language is understood by ${accessibility} reading level.`
    }
    const result = await getResponse(prompt);
    setFormData({ ...formData, generatedText: result, loading: false });
  }
  useEffect(() => {

    if (generalFormRef?.current) generalFormRef.current.focus();
  }, [generalFormRef]);

  return (
    <>
      <section ref={generalFormRef} className="flex justify-center flex-col">
        <h3 className="text-4xl font-bold text-center mt-6 mb-12 leading-relaxed">{pageTitle}</h3>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
          <form onSubmit={handleSubmit}>
            {isProduct && <div className="mb-6">
              <label htmlFor="title" className="block mb-2 text-md font-medium text-cyan-800">{label1}</label>
              <input type="text" minLength={minLength} maxLength={maxLength} value={title} onChange={handleChange} name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none" placeholder={label1} />
            </div>}
            <div className="grid lg:grid-cols-2 gap-3 mb-2">
              <div>
                <label htmlFor="tone" className="block mb-2 text-md font-medium text-cyan-800">Tone of voice</label>
                <select value={tone} onChange={handleChange} name="tone" id="tone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none  ">
                  <option value="informative">Informative</option>
                  <option value="creative">Creative</option>
                  <option value="luxury">Luxury</option>
                  <option value="funny">Funny</option>
                  <option value="smart">Smart</option>
                </select>
              </div>
              <div>
                <label htmlFor="words" className="block mb-2 text-md font-medium text-cyan-800  ">Word count</label>
                <input type="number" min={10} max={300} value={words} onChange={handleChange} name="words" id="words" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none" placeholder="0" />
              </div>
              <div className="">
                <label htmlFor="reading" className="block mb-2 text-md font-medium text-cyan-800">Target audience (ie. Who is the copy for?)</label>
                <Select
                  value={reading}
                  onChange={handleReadingChange}
                  options={options}
                  isSearchable={true}
                  classNames={{
                    menuButton: () => (
                      `flex bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 w-full focus:outline-none transition-all duration-300`
                    ),
                  }}
                />
              </div>
              <div className="">
                <label htmlFor="accessibility" className="block mb-2 text-md font-medium text-cyan-800">Accessibility reading level</label>
                <select value={accessibility} onChange={handleChange} name="accessibility" id="accessibility" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none  ">
                  <option value="12 years">12 years</option>
                  <option value="15 years">15 years</option>
                  <option value="18 years">18 years</option>
                </select>
              </div>
            </div>
            <div className="mb-2">
              <label htmlFor="keywords" className="block mb-2 text-md font-medium text-cyan-800  ">Keywords</label>
              <input type="text" minLength={3} maxLength={maxLength} value={keywords} onChange={handleChange} name="keywords" id="keywords" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none" placeholder="Marketing, affiliate, content" />
            </div>
            <div className="mb-6">
              <label htmlFor="message" className="block mb-2 text-md font-medium text-cyan-800  ">{label2}</label>
              <textarea id="message" minLength={minLength} value={message} onChange={handleChange} name="message" rows="10" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:outline-none focus:border-cyan-500" placeholder={label2}></textarea>
              <p className="mt-2 text-sm text-gray-700">Be as specific as you can with 10 or more characters.</p>
            </div>
            <div className="flex items-center mb-2">
              <input id="finance" name="finance" type="checkbox" checked={finance} onChange={(e) => { setFinance(e.target.checked); }} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 " />
              <label htmlFor="finance" className="ml-2 text-sm font-medium text-gray-900">Generate general advice only.</label>
            </div>
            <p className="mb-6 mt-2 text-sm text-gray-700">Note - it is recommended that all copy generated by this tool be reviewed and approved by your legal, risk and compliance representatives.</p>
            <Button btnText={btnText} loading={loading} />
          </form>
          <Content generatedText={generatedText} loading={loading} />
        </div>
      </section>
    </>
  )
})
GeneralForm.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
}
export default GeneralForm