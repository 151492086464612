import { UserContext } from "../../App";
import { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../services/userService";
import GeneralSpinner from "../../components/layouts/GeneralSpinner";

import { toast } from 'react-toastify';
import LeftLoginSignup from "../../components/layouts/LeftLoginSignup";


const SignUp = () => {
  const { state, dispatch } = useContext(UserContext);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    cpassword: ''
  });
  const { username, email, password, cpassword } = formData;
  const navigate = useNavigate();

  useEffect(() => {
    if (state.isError) {
      toast.error(state.message);
    }
    if (state.isSuccess || state.user) {
      navigate('/tools');
    }
    dispatch({ type: "RESET" });
  }, [state.isError, state.isSuccess, state.isLoading, state.message, navigate, state.user, dispatch]);

  // setForm data values
  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const redirectToGoogleSSO = async () => {
    const googleLoginURL = `${process.env.REACT_APP_BACKEND_URL}auth/google`;
    window.open(
      googleLoginURL,
      "_self",
    );
  };
  const redirectToFacebookSSO = async () => {
    const facebookLoginURL = `${process.env.REACT_APP_BACKEND_URL}auth/facebook`;
    window.open(
      facebookLoginURL,
      "_self",
    );
  };
  // Submit the htmlForm
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== cpassword) {
      toast.error("Password mismatch", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      const userData = { username, email, password };
      await register(userData, dispatch);
    }
  }

  if (state.isLoading) {
    return <GeneralSpinner />
  }
  return (
    <div className="h-full flex justify-center items-center">
      <div className="signTemplate h-screen w-full signTemplate grid lg:grid-cols-2 grid-cols-1">
        <LeftLoginSignup />
        <div className="signColor flex justify-center items-center flex-col p-4">
          <h3 className="mb-6 text-4xl font-semibold text-center">Sign Up</h3>
          <form className="formWidth" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="username" className="block mb-2 text-sm font-light">Username</label>
              <input type="text" value={username} onChange={handleChange} name="username" id="username" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none" placeholder="John Smith" />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block mb-2 text-sm font-light">Email</label>
              <input type="email" value={email} onChange={handleChange} name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none" placeholder="john@gmail.com" />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block mb-2 text-sm font-light">Password</label>
              <input type="password" value={password} onChange={handleChange} name="password" id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none" placeholder="Password" />
            </div>
            <div className="mb-4">
              <label htmlFor="cpassword" className="block mb-2 text-sm font-light">Confirm Password</label>
              <input type="password" value={cpassword} onChange={handleChange} name="cpassword" id="cpassword" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none" placeholder="Confirm Password" />
            </div>
            <div className="mb-6">
              <div className="flex items-center">
                <input id="terms" type="checkbox" value="" className="w-4 h-4 text-cyan-700 bg-gray-100 border-gray-300 rounded focus:ring-cyan-500 focus:ring-2" required />
                <label htmlFor="terms" className="ml-2 text-sm font-medium text-gray-900">I agree with the <Link to="/terms-conditions" className="text-cyan-700 hover:underline">terms and conditions</Link>.</label>
              </div>
            </div>
            <div className="flex justify-center">
              <button className="rounded-lg px-4 py-2 bg-cyan-500 text-white text-md hover:bg-cyan-300">Log In</button>
            </div>
            <div className="text-sm text-center mt-4">
              <Link to="/login">Already signed up? Click here to <span className="text-cyan-700 font-medium">Log in!</span></Link>
            </div>
            <hr className="container bg-gray-400 my-4" />
          </form>
          <div className="flex justify-center">
            <button onClick={redirectToGoogleSSO} className="hover:border-gray-300 text-red-500 bg-transparent px-4 py-2 rounded-full border border-gray-200">
              <i className="fa-brands fa-google mr-3"></i>
              <span className="text-red-500">Google</span>
            </button>
            <button onClick={redirectToFacebookSSO} className="hover:border-gray-300 ml-5 text-blue-500 bg-transparent px-4 py-2 rounded-full border border-gray-200" >
              <i className="fa-brands fa-facebook mr-3"></i>
              <span className="text-blue-500">Facebook</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SignUp