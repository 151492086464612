import { useState } from "react";
import { forgotPassword } from "../../services/userService";
import { toast } from 'react-toastify';

const ForgotPassForm = ({ showModal, setShowModal }) => {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await forgotPassword(email);
            setSuccess(true);
            setEmail("");
            setTimeout(() => {
                setShowModal(false);
                setSuccess(false);
            }, 3000);
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    }
    return (
        <>
            {showModal ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            {success && <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                                <span className="font-medium">Success!</span> After few minutes check your email.
                            </div>}
                            <div className="w-[600px] border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*body*/}
                                <form onSubmit={handleSubmit} className="p-6">
                                    <div className="relative">
                                        <label htmlFor="email" className="block mb-2 text-md font-normal">Email</label>
                                        <div className="">
                                            <div className="absolute top-[32px] bottom-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <svg className="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
                                            </div>
                                        </div>
                                        <input type="email" onChange={(e) => setEmail(e.target.value)} name="email" id="email" value={email} className="outline-0 block w-full p-4 text-xs md:text-sm pl-10 text-gray-900 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500" placeholder="Type your email" required />
                                        <button type="submit" className="bg-blue-600 text-white absolute right-2.5 bottom-2.5 outline-0 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2"><span>Send</span></button>
                                    </div>
                                </form>
                                {/*footer*/}
                                <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button className="text-indigo-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button" onClick={() => setShowModal(false)} >
                                        Close </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}

        </>
    )
}
export default ForgotPassForm