export const data = [
    {
        id:1,
        icone:"fa-file-lines",
        title:"Topics and Articles",
        text:"Discover attention-grabbing topics for your article or blog and effortlessly create structured content.",
        link:"/blog"
    },
    {
        id:2,
        icone:"fa-pen-to-square",
        title:"Rewrite and Optimise",
        text:"Boost your SEO rankings with ease by rewriting and keyword optimising articles, web copy and blogs.",
        link:"/rewrite"
    },
    {
        id:3,
        icone:"fa-quote-right",
        title:"YouTube, Meta and Product Descriptions",
        text:"Punch out search optimised descriptions for your videos, meta descriptions and products in minutes.",
        link:"/description"
    },
    {
        id:4,
        icone:"fa-globe",
        title:"Social Media Posts",
        text:"Create scroll-stopping social media posts for Facebook, Twitter, LinkedIn and more.",
        link:"/posts"
    },
    {
        id:5,
        icone:"fa-question",
        title:"FAQs",
        text:"Generate Frequently Asked Questions (FAQ’s) for your business website or knowledge base.",
        link:"/faq"
    },
    {
        id:6,
        icone:"fa-envelope",
        title:"Email",
        text:"Craft captivating emails for marketing, sales, customer service and more in minutes.",
        link:"/email"
    },
    
]