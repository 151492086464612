const Terms = () => {
    return (
        <>
            <div className="container flex flex-col py-12 lg:pb-24">
                <div className="text-justify w-[90%]">
                    <h1 className="font-bold text-2xl lg:text-4xl">TERMS AND CONDITIONS</h1>
                    <p className="my-4 font-semibold text-md">Welcome to <span className="text-cyan-500">Content Crunch</span> AI Writing Tool. These terms and conditions outline the rules and regulations for the use of our service.</p>
                    <p className="my-4 font-semibold text-md">By using Content Crunch AI Writing Tool, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use our service.</p>
                    <h2 className="font-bold text-lg lg:text-xl">License:</h2>
                    <p className="mb-4 font-normal text-md">Content Crunch AI Writing Tool grants you a non-exclusive, non-transferable license to access and use the service for your personal or commercial purposes.</p>
                    <h2 className="font-bold text-lg lg:text-xl">Ownership:</h2>
                    <p className="mb-4 font-normal text-md">Content Crunch AI Writing Tool is the property of its owner and is protected by copyright and other intellectual property laws. You are not allowed to copy, modify, or distribute any part of the service without our express written permission.</p>
                    <h2 className="font-bold text-lg lg:text-xl">Use of the Service:</h2>
                    <p className="mb-4 font-normal text-md">You are responsible for any content you generate using Content Crunch AI Writing Tool. You must ensure that the content complies with all applicable laws and regulations, including copyright and privacy laws.</p>
                    <h2 className="font-bold text-lg lg:text-xl">License:</h2>
                    <p className="mb-4 font-normal text-md">Content Crunch AI Writing Tool grants you a non-exclusive, non-transferable license to access and use the service for your personal or commercial purposes.</p>
                    <p className="my-4 font-normal text-md">You must not use our service for any illegal or unauthorized purpose. You must not transmit any worms or viruses or any code of a destructive nature.</p>
                    <h2 className="font-bold text-lg lg:text-xl">Privacy:</h2>
                    <p className="mb-4 font-normal text-md">We take your privacy seriously. We collect, process, and store your personal data in accordance with our Privacy Policy, which you can access on our website.</p>
                    <h2 className="font-bold text-lg lg:text-xl">Disclaimer of Warranties:</h2>
                    <p className="mb-4 font-normal text-md">Our AI Writing Tool is provided "as is" and without warranties of any kind, either express or implied. We do not guarantee that the service will be uninterrupted or error-free, and we do not guarantee the accuracy, completeness, or timeliness of the content generated by our service.</p>
                    <h2 className="font-bold text-lg lg:text-xl">Limitation of Liability:</h2>
                    <p className="mb-4 font-normal text-md">We will not be liable for any damages of any kind arising out of or relating to the use or inability to use our AI Writing Tool, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>
                    <h2 className="font-bold text-lg lg:text-xl">Termination:</h2>
                    <p className="mb-4 font-normal text-md">We reserve the right to terminate your access to our AI Writing Tool at any time without notice.</p>
                    <h2 className="font-bold text-lg lg:text-xl">Modifications:</h2>
                    <p className="mb-4 font-normal text-md">We may modify these terms and conditions at any time, and such modifications will be effective immediately upon posting on our website.</p>
                    <h2 className="font-bold text-lg lg:text-xl">Governing Law:</h2>
                    <p className="mb-4 font-normal text-md">These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction where our company is registered, without regard to its conflict of law provisions.</p>
                    <h2 className="font-bold text-lg lg:text-xl">Entire Agreement:</h2>
                    <p className="mb-4 font-normal text-md">These terms and conditions constitute the entire agreement between you and us regarding the use of Content Crunch AI Writing Tool and supersede all prior agreements and understandings.</p>
                    <p className="mb-4 font-normal text-md">If you have any questions about these terms and conditions, please contact us at <span className="text-cyan-500">info@contentcrunch.ai</span></p>
                </div>
            </div>
        </>
    )
}
export default Terms