import HeroForms from "../components/layouts/HeroForms";
import GeneralForm from "../components/GeneralForm";
import Img from "../assets/images/description.png";

import { useEffect, useRef } from "react";


const Description = () => {
  // Create a reference to jump or scroll to the view underneath Hero section
  const generalFormRef = useRef(null);
  // Scroll down when clicking on start
  const handleClick = () => {
    generalFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  // Go to the top and change the document title
  useEffect(() => {
    document.title = "Description";
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <HeroForms handleClick={handleClick} backImage={Img} copy="YouTube, Meta Description and Product Description Generator" subcopy="Punch out search optimised descriptions for your videos, meta descriptions and products in minutes" />
      <div className="lg:pt-12 py-6">
        <GeneralForm ref={generalFormRef} pageTitle="Start Generating YouTube and Meta Descriptions" maxLength={500} minLength={10} type="META_DESCRIPTION" btnText="Generate description" label2="What’s your content about?" />
      </div>
      <hr className="my-8 border-gray-300 sm:mx-auto" />
      <div className="pt-2 lg:pb-32 md:pb-20 sm:pb-12">
        <GeneralForm pageTitle="Start Generating Product Descriptions" isProduct={true} maxLength={500} minLength={10} type="PRODUCT_DESCRIPTION" btnText="Generate product description" label1="What's your product name?" label2="Tell us more about your product (what does it do?)" />
      </div>
    </>
  )
}
export default Description