import axios from "axios";

export const register = async (userData, dispatch) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "users", userData);
    dispatch({ type: "REGISTER_USER", payload: response.data });
    localStorage.setItem("user", JSON.stringify(response.data));
  } catch (error) {
    dispatch({ type: "ERROR", payload: error.response.data.message });
  }
}

export const login = async (userData, dispatch) => {
  try {
    const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "users/login", userData);
    dispatch({ type: "LOGIN_USER", payload: response.data });
    localStorage.setItem("user", JSON.stringify(response.data));
  } catch (error) {
    dispatch({ type: "ERROR", payload: error.response.data.message });
  }
}
export const forgotPassword = async (email) => {
  try {
    await axios.post(process.env.REACT_APP_BACKEND_URL + "users/forgot-password", { email });
  } catch (error) {
    throw new Error(error.response.data.message)
  }
}
export const resetPassword = async (token, password) => {
  try {
    await axios.post(process.env.REACT_APP_BACKEND_URL + "users/reset-password/" + token, { password });
  } catch (error) {
    console.log(error.response.data.message)
    throw new Error(error.response.data.message)
  }
}

export const fetchAuthUser = async (dispatch) => {
  try {
    const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "users/auth/user", { withCredentials: true });
    dispatch({ type: "LOGIN_USER", payload: response.data });
    localStorage.setItem("user", JSON.stringify(response.data));
  } catch (error) {
    console.log(error)
    dispatch({ type: "ERROR", payload: error.response.data.message });
  }
}

export const Logout = async (state, dispatch) => {
  if (state.user.googleId || state.user.facebookId) {
    await axios.get(process.env.REACT_APP_BACKEND_URL + "users/auth/logout", { withCredentials: true });
  }
  // Cookies.remove('Token');
  dispatch({ type: "LOGOUT" });
  localStorage.removeItem("user");
  dispatch({ type: "RESET" });
}