import { Link } from "react-router-dom";
import { data } from "../data";


const Tools = () => {
    return (
        <section className="py-12 flex justify-center items-center">
            <div className="text-center">
                <h3 className="lg:text-4xl text-2xl font-bold leading-relaxed">Start crunching out professional content in minutes!</h3>
                <div className="grid lg:grid-cols-3 items-stretch gap-6 justify-center items-center lg:py-20 py-12">
                    {data.map((item) => {
                        return <Link to={item.link} key={item.id} className="cursor-pointer max-w-sm py-8 px-6 bg-indigo-50 hover:shadow-lg rounded-lg shadow-md flex justify-center items-center flex-col">
                            <h3 className="text-2xl text-cyan-500"><i className={`fa-solid ${item.icone}`}></i></h3>
                            <p className="my-2 text-xl font-semibold">{item.title}</p>
                            <p className="leading-relaxed mb-3 text-gray-500">{item.text}</p>
                        </Link>
                    })}
                </div>
            </div>
        </section>
    )
}
export default Tools