import { useState } from "react";
import { resetPassword } from "../../services/userService";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/layouts/Header";
import Footer from "../../components/layouts/Footer";
import { toast } from 'react-toastify';

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();
    let { token } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            toast.error("Password mismatch", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
        try {
            await resetPassword(token, password);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                navigate("/login")
            }, 3000);
        } catch (error) {
            toast.error("Invalid or expired url", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
    };
    return (
        <>
            <Header />
            <section className="flex bg-gray-50 justify-center h-full md:pb-24 pb-12 md:pt-20 pt-6">
                <div className="bg-white p-6 shadow-lg rounded-lg w-[550px]">
                    {success && <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
                        <span className="font-medium">Success!</span> Your password has changed successfully.
                    </div>}
                    <h2 className="text-lg font-bold my-4">Reset Password</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label htmlFor="password" className="block mb-2 text-sm font-light"> New Password: </label>
                            <input
                                type="password"
                                name="password"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="confirmPassword" className="block mb-2 text-sm font-light"> Confirm New Password: </label>
                            <input
                                type="password"
                                name="confirmPassword"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="rounded-lg text-white hover:bg-red-600 bg-red-500 px-4 py-2">Reset Password</button>
                    </form>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default ResetPassword