import HeroForms from "../components/layouts/HeroForms";
import Input from "../components/Input";
import Img from "../assets/images/faq.png";

import { useEffect, useRef } from "react";


const FAQ = () => {
  // Create a reference to jump or scroll to the view underneath Hero section
  const inputRef = useRef(null);
  // Scroll down when clicking on start
  const handleClick = () => {
    inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  // Go to the top and change the document title
  useEffect(() => {
    document.title = "FAQs";
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <HeroForms handleClick={handleClick} backImage={Img} copy="FAQ Generator" subcopy="Generate Frequently Asked Questions (FAQ’s) for your business website or knowledge base" />
      <div className="lg:pb-20 lg:pt-12 md:py-12 sm:py-6">
        <h3 className="text-4xl font-bold text-center mt-6 mb-12 leading-relaxed">Start Generating FAQ’s</h3>
        <Input ref={inputRef} isFaq={true} maxLength={500} minLength={4} type="FAQ" btnText="Generate FAQ" label="Topic or product" />
      </div>
    </>
  )
}
export default FAQ