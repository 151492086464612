import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import Home from "./pages/Home";
import NotFound from "./components/layouts/NotFound";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createContext, useEffect, useReducer } from "react";


import BlogForm from "./pages/BlogForm";
import Description from "./pages/Description";
import RewriteArticle from "./pages/RewriteArticle";
import SocialMediaPosts from "./pages/SocialMediaPosts";
import FAQ from "./pages/FAQ";
import Email from "./pages/Email";

import { initialState } from "./Reducers/authReducer";
import { authReducer } from "./Reducers/authReducer";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import ForgotPassForm from "./pages/auth/ForgotPassForm";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAuthUser } from "./services/userService";
import Tools from "./pages/Tools";
import Terms from "./pages/menu/Terms";
import ResetPassword from "./pages/auth/ResetPassword";

import packageJson from '../package.json';

export const UserContext = createContext();


function App() {

  const [state, dispatch] = useReducer(authReducer, initialState);
  useEffect(() => {
    fetchAuthUser(dispatch)
  }, [])
  useEffect(() => {
    const checkAndUpdateCache = () => {
      let version = localStorage.getItem('version-crunch');
      if (version !== packageJson.version) {
        if ('caches' in window) {
          caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach((name) => {
              caches.delete(name);
            });
          });

          // Makes sure the page reloads. Changes are only visible after you refresh.
          window.location.reload(true);
        }
        localStorage.clear();
        localStorage.setItem('version-crunch', packageJson.version);
      }
    };

    checkAndUpdateCache();
  }, []);
  return (
    <>
      <Router>
        <UserContext.Provider value={{ state, dispatch }}>
          <ToastContainer />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassForm />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route
              path="/*"
              element={
                <>
                  <Header />
                  <main className="flex min-h-screen font-roboto">
                    <div className="container mx-auto px-12 sm:px-4 flex-1">
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/tools" element={<Tools />} />
                        <Route path="/blog" element={<BlogForm />} />
                        <Route path="/description" element={<Description />} />
                        <Route path="/rewrite" element={<RewriteArticle />} />
                        <Route path="/posts" element={<SocialMediaPosts />} />
                        <Route path="/terms-conditions" element={<Terms />} />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/email" element={<Email />} />
                        <Route path='*' element={<NotFound />} />
                      </Routes>
                    </div>
                  </main>
                  <Footer />
                </>
              }
            />
          </Routes>
        </UserContext.Provider>
      </Router>
    </>
  );
}

export default App;
