import HeroForms from "../components/layouts/HeroForms";
import GeneralForm from "../components/GeneralForm";
import Img from "../assets/images/email.png";

import { useEffect, useRef } from "react";

const Email = () => {
  // Create a reference to jump or scroll to the view underneath Hero section
  const generalFormRef = useRef(null);
  // Scroll down when clicking on start
  const handleClick = () => {
    generalFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  // Go to the top and change the document title
  useEffect(() => {
    document.title = "Email";
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <HeroForms handleClick={handleClick} backImage={Img} copy="Email Generator" subcopy="Craft captivating emails for marketing, sales, customer service and more in just a few minutes" />
      <div className="lg:pb-32 lg:pt-12 py-6">
        <GeneralForm ref={generalFormRef} pageTitle="Start Generating Emails" maxLength={500} minLength={10} type="EMAIL" btnText="Generate email" label2="What's your email about?" />
      </div>
    </>
  )
}
export default Email