import HeroForms from "../components/layouts/HeroForms";
import Input from "../components/Input";
import Img from "../assets/images/socialMedia.png";

import { useEffect, useRef } from "react";



const SocialMediaPosts = () => {
  const selectData = ["Facebook", "Instagram", "LinkedIn", "Twitter"];
  // Create a reference to jump or scroll to the view underneath Hero section
  const inputRef = useRef(null);
  // Scroll down when clicking on start
  const handleClick = () => {
    inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  // Go to the top and change the document title
  useEffect(() => {
    document.title = "Description";
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <HeroForms handleClick={handleClick} backImage={Img} copy="Social Media Post Generator" subcopy="Create scroll-stopping social media posts for Facebook, Twitter, LinkedIn and more" />
      <div className="lg:pb-20 lg:pt-12 md:py-12 sm:py-6">
        <h3 className="text-4xl font-bold text-center mt-6 mb-12 leading-relaxed">Start Generating Social Media Posts</h3>
        <Input ref={inputRef} selectData={selectData} maxLength={500} minLength={4} type="SOCIAL_MEDIA_POST" btnText="Generate post" label="What is your post about?" />
      </div>
    </>
  )
}
export default SocialMediaPosts