
const HeroForms = ({handleClick, copy, subcopy, backImage}) => {
  return (
    <section className="pt-12">
        <div className="grid lg:grid-cols-2 gap-4 justify-center items-center">
            <div className="text-center lg:text-left">
                <h1 className="text-3xl font-bold leading-relaxed">{copy}</h1>
                <h2 className="mt-2 text-gray-500 lg:text-lg text-md max-w-2xl">{subcopy}</h2>
                <button onClick={handleClick} className="mt-4 rounded-full bg-cyan-500 text-zinc-100 px-8 py-4 hover:bg-cyan-300">Start Writing</button>
            </div>
            <div className="lg:flex justify-center hidden">
                <img src={backImage} className="w-4/5" alt="Hero forms" />
            </div>
        </div>
    </section>
  )
}
export default HeroForms