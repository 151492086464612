import axios from "axios";
 
export const getResponse = async (prompt) => {
    const response = await axios.post(process.env.REACT_APP_BACKEND_URL+"chatgpt", {prompt}, {
        headers:{
            'x-access-token': getToken()
        }
    });
    return response.data.result;
}

const getToken =()=>{
    const user = JSON.parse(localStorage.getItem("user"));
    return user.token;
  }