const Footer = () => {
  return (
  <footer className="mt-12 mx-auto bg-cyan-500">   
     <div className="text-center py-6">
         <span className="text-md text-white">© 2023 <a href="!#" className="hover:underline">Content Crunch</a>. All Rights Reserved.
         </span>
     </div>
 </footer>
  )
}
export default Footer