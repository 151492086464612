import Img from "../../assets/images/ai.png";



const Hero = ({ handleClick }) => {
    return (
        <>
            <section className="flex justify-center items-center flex-col pt-12 pb-6">
                <div className="text-center">
                    <div className="flex justify-center items-center flex-col">
                        <h1 className="lg:text-6xl text-4xl font-bold leading-relaxed">Transform your Financial Services content strategy with <br></br>
                            <span className="text-cyan-500">Content Crunch</span></h1>
                        <h2 className="mt-2 text-gray-500 lg:text-lg text-sm max-w-2xl">The ultimate AI-powered generator</h2>
                        <button onClick={handleClick} className="mt-4 rounded-full bg-cyan-500 text-zinc-100 px-8 py-4 hover:bg-cyan-300">Start Writing</button>
                    </div>
                </div>
            </section>
            <div className="w-full flex justify-center items-center">
                <img src={Img} alt="Ai" style={{ width: '25%' }} />
            </div>
            <section className="flex justify-center items-center py-4">
                <div className="grid lg:grid-cols-3 gap-7 border-b-2 border-gray-200">
                    <div className="text-center p-6">
                        <h4 className="font-semibold text-xl">Content Writers</h4>
                        <p className="text-md text-gray-500 mt-1">Generate highly engaging articles, web copy, emails and FAQs in minutes.</p>
                    </div>
                    <div className="text-center p-6">
                        <h4 className="font-semibold text-xl">Social Media Managers</h4>
                        <p className="text-md text-gray-500 mt-1">Create scroll-stopping social posts to engage and build your audience.</p>
                    </div>
                    <div className="text-center p-6">
                        <h4 className="font-semibold text-xl">SEO Managers</h4>
                        <p className="text-md text-gray-500 mt-1">Boost search rankings by optimising your new and existing content.</p>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Hero