import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { useContext } from "react";
import { Logout } from "../../services/userService";

const Header = () => {
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const onLogout = async () => {
    await Logout(state, dispatch);
    navigate("/login");
  }
  return (
    <header>
      <nav className="font-nunito container mx-auto flex justify-between py-6">
        <Link to="/" id="logo">
          <img className="logo" src={logo} alt="logo" />
        </Link>
        <div className="lg:flex items-center text-lg font-semibold">
          <Link to="/" className="mr-5 block text-lg lg:inline-block lg:mt-0">Home</Link>
          {state.user ? (<>
            <button onClick={onLogout} className="block text-lg lg:inline-block rounded-lg px-4 py-2 bg-cyan-500 text-white hover:bg-cyan-300 transition ease-in lg:mt-0">Logout</button>
          </>) : (<>
            <Link to="/login" className="block text-lg lg:inline-block rounded-lg px-4 py-2 bg-cyan-500 text-white hover:bg-cyan-300 transition ease-in lg:mt-0">Login</Link>
          </>)}
        </div>

      </nav>
    </header>
  )
}
export default Header