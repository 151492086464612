import Button from "./layouts/Button";
import { getResponse } from "../services/openaiService";
import Content from "./Content";
import { useState, forwardRef } from "react";
import Select from 'react-tailwindcss-select';

import PropTypes from 'prop-types';

const options = [
  { value: "Target audience", label: "Target audience", disabled: true },
  { value: "5-10", label: "5-10" },
  { value: "10-15", label: "10-15" },
  { value: "15-24", label: "15-24" },
  { value: "25-29", label: "25-29" },
  { value: "30-34", label: "30-34" },
  { value: "35-39", label: "35-39" },
  { value: "40-44", label: "40-44" },
  { value: "45-49", label: "45-49" },
  { value: "50-54", label: "50-54" },
  { value: "60-64", label: "60-64" },
  { value: "65-69", label: "65-69" },
  { value: "70-74", label: "70-74" },
  { value: "75-80", label: "75-80" },
];


const Input = forwardRef(({ type, selectData, label, btnText, maxLength, minLength }, inputRef) => {
  const [formData, setFormData] = useState({
    title: "", tone: "", accessibility: "15 years",
    keywords: "", dataType: "",
    reading: { value: "Target audience", label: "Target audience", disabled: true }, faq: 1, words: 30,
    loading: false, generatedText: null
  });
  const [finance, setFinance] = useState(false)
  const { title, tone, accessibility, faq, reading, keywords, words, dataType, generatedText, loading } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const handleReadingChange = (value) => {
    setFormData({ ...formData, reading: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormData({ ...formData, loading: true });
    let prompt;
    if (type === "GENERATE_BLOG") {
      prompt = `Write a ${tone} ${dataType} for my blog the title is ${title}, using the Keywords [${keywords}], in ${words} words or more.
      Make it suitable for people that has around ${reading}.\nMake sure that the language is understood by ${accessibility} reading level.
      ${finance ? 'Please respect RG146 advice giving rules way and dont give a direct financial advice.' : ''}`;
    } else if (type === "SOCIAL_MEDIA_POST") {
      prompt = `Write a ${tone} ${dataType} post with 100 words about: ${title}, using the Keywords [${keywords}], in ${words} words or more.
      Make it suitable for people that has around ${reading}.\nMake sure that the language is understood by ${accessibility} reading level.
      ${finance ? 'Please respect RG146 advice giving rules way and dont give a direct financial advice.' : ''}`;
    } else if (type === "FAQ") {
      prompt = `Write me different ${faq} FAQ about: ${title}, in ${words} words or less.
       Make it suitable for people that has around ${reading}.
       ${finance ? 'Please respect RG146 advice giving rules way and dont give a direct financial advice.' : ''}.
       Make sure that the language is understood by ${accessibility} reading level.`;
    }
    const result = await getResponse(prompt);
    setFormData({ ...formData, generatedText: result, loading: false });
  }
  return (
    <section ref={inputRef} className="flex justify-center flex-col">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-3">
        <form onSubmit={handleSubmit}>
          <div className="grid lg:grid-cols-2 gap-3 mb-2">
            {type !== "FAQ" && <>
              <div className="">
                <label htmlFor="dataType" className="block mb-2 text-md font-medium text-cyan-800  ">Select from the list</label>
                <select value={dataType} onChange={handleChange} id="dataType" name="dataType" className="outline-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 capitalize">
                  {selectData.map((item) => {
                    return <option key={item} value={item}>{item}</option>
                  })}
                </select>
              </div>
              <div>
                <label htmlFor="accessibility" className="block mb-2 text-md font-medium text-cyan-800">Accessibility reading level</label>
                <select value={accessibility} onChange={handleChange} name="accessibility" id="accessibility" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none  ">
                  <option value="12 years">12 years</option>
                  <option value="15 years">15 years</option>
                  <option value="18 years">18 years</option>
                </select>
              </div>
              <div>
                <label htmlFor="tone" className="block mb-2 text-md font-medium text-cyan-800">Tone of voice</label>
                <select value={tone} onChange={handleChange} name="tone" id="tone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none  ">
                  <option value="informative">Informative</option>
                  <option value="creative">Creative</option>
                  <option value="luxury">Luxury</option>
                  <option value="funny">Funny</option>
                  <option value="smart">Smart</option>
                </select>
              </div>
            </>}
            <div className="">
              <label htmlFor="title" className="block mb-2 text-md font-medium text-cyan-800">{label}</label>
              <input type="text" maxLength={maxLength} minLength={minLength} name="title" id="title" value={title} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none" placeholder={label} />
            </div>
            <div className="">
              <label htmlFor="reading" className="block mb-2 text-md font-medium text-cyan-800">Target audience (ie. Who is the copy for?)</label>
              <Select
                value={reading}
                onChange={handleReadingChange}
                options={options}
                isSearchable={true}
                classNames={{
                  menuButton: () => (
                    `flex bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 w-full focus:outline-none transition-all duration-300`
                  ),
                }}
              />
            </div>
            <div className="">
              <label htmlFor="words" className="block mb-2 text-md font-medium text-cyan-800">Word count</label>
              <input type="number" min={1} max={300} name="words" id="words" value={words} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none" placeholder="0" />
            </div>
            {type === "FAQ" && <div className="">
              <label htmlFor="faq" className="block mb-2 text-md font-medium text-cyan-800">Number of FAQ’s</label>
              <input type="number" min={1} max={20} name="faq" id="faq" value={faq} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none" placeholder="0" />
            </div>
            }
          </div>
          <div className="mb-6">
            <label htmlFor="keywords" className="block mb-2 text-md font-medium text-cyan-800">Keywords</label>
            <input type="text" minLength={3} maxLength={maxLength} value={keywords} onChange={handleChange} name="keywords" id="keywords" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-500 focus:border-cyan-500 block w-full p-2.5 focus:outline-none" placeholder="Marketing, affiliate, content" />
          </div>
          <div className="flex items-center">
            <input id="finance" name="finance" type="checkbox" checked={finance} onChange={(e) => { setFinance(e.target.checked); }} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 " />
            <label htmlFor="finance" className="ml-2 text-sm font-medium text-gray-900">Generate general advice only.</label>
          </div>
          <p className="mb-6 mt-2 text-sm text-gray-700">Note - it is recommended that all copy generated by this tool be reviewed and approved by your legal, risk and compliance representatives.</p>
          <Button btnText={btnText} loading={loading} />
        </form>
        <Content generatedText={generatedText} loading={loading} />
      </div>
    </section>
  )
})
Input.propTypes = {
  title: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
}
export default Input;