import GeneralForm from "../components/GeneralForm"
import Input from "../components/Input";
import { useEffect, useRef } from "react";
import Img from "../assets/images/articles.png";
import HeroForms from "../components/layouts/HeroForms";

const BlogForm = () => {
  const selectData = ["introduction", "conclusion", "outlines", "content"];
  const generalFormRef = useRef(null);

  const handleClick = () => {
    generalFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  useEffect(() => {
    document.title = "Blog";
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <HeroForms handleClick={handleClick} copy="Topic and Article Generator" subcopy="Discover attention-grabbing topics for your article or blog and effortlessly create structured content" backImage={Img} />
      <div className="lg:pt-12 pb-6 pt-6">
        <GeneralForm ref={generalFormRef} pageTitle="Start Generating Topics" maxLength={500} minLength={10} type="BLOG_IDEA" btnText="Generate Ideas" label2="Article topic (eg. Digital Marketing in 2023)" />
      </div>
      <hr className="my-8 border-gray-300 sm:mx-auto" />
      <div className="pb-32">
        <h3 className="text-4xl font-bold text-center my-6 leading-relaxed">Now Generate Your Article</h3>
        <Input selectData={selectData} maxLength={500} minLength={10} type="GENERATE_BLOG" btnText="Generate article" label="Article topic" />
      </div>
    </>
  )
}
export default BlogForm