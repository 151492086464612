import GeneralForm from "../components/GeneralForm";
import HeroForms from "../components/layouts/HeroForms";
import Img from "../assets/images/rewrite.png";

import { useEffect, useRef } from "react";


const RewriteArticle = () => {
  // Create a reference to jump or scroll to the view underneath Hero section
  const generalFormRef = useRef(null);
  // Scroll down when clicking on start
  const handleClick = () => {
    generalFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  // Go to the top and change the document title
  useEffect(() => {
    document.title = "Rewrite Article";
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <HeroForms handleClick={handleClick} backImage={Img} copy="Rewrite and Optimisation Generator" subcopy="Boost your SEO rankings with ease by rewriting and keyword optimising articles, web copy and blogs" />
      <div className="lg:pb-20 lg:pt-12 md:py-12 sm:py-6">
        <GeneralForm ref={generalFormRef} pageTitle="Start Rewriting and Optimising" minLength={10} type="REWRITE_PARAGRAPH" btnText="Rewrite copy" label1="What's your blog title? (e.g. Digital Marketing 2023 Pros and Cons)" label2="Paste in your copy and break into short paragraphs for best results" />
      </div>
    </>
  )
}
export default RewriteArticle